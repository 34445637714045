<template>
  <div class="tradeIndexcontainer">
     <router-view/>
  </div>
</template>

<script>

export default {
  name: 'tradeIndex',
  components: {
      
  }
}

</script>

<style scoped>
  
</style>
